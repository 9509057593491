<template>
<b-overlay :loading="loading">
    <div>
      <div>
        <div>
          <div v-if="json_data_initiated" class="my-editor">
            <json-editor
              v-if="showEditor"
              :key="policyUpdatedKey"
              mode="code"
              :content="content"
              :read-only="readOnly"
              @onChange="onChange"
              @disableUpdateButton="_ => disable_update_button = true"
              @enableUpdateButton="_ => disable_update_button = false"
            />

            <div class="d-flex justify-content-between align-items-center">
              <b-button class="mt-2" variant="primary" size="lg" :disabled="disable_update_button" @click="updatePolicy(content)">
                Update Policy
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
</b-overlay>
</template>

<script>
import store from "@/store";
import { MUTATE_USER_DATA } from "@/store/config/mutation-keys";
import { BCard, BTabs, BTab, BCardText, BFormInput, BFormGroup, BButton, BOverlay } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { get, pick } from 'lodash';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { validatePolicy } from "@core/utils/validations/validators"

import JsonEditor from "./JSONEditor.vue";
import PolicyStatementsEditor from "./PolicyStatementsEditor.vue"

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
    JsonEditor,
    BFormInput,
    BFormGroup,
    BButton,
    BOverlay,

    ValidationProvider,
    ValidationObserver,
    PolicyStatementsEditor
  },
  data() {
    return {
      data: {},
      policy: {
        name: "",
        statements: new Set()
      },
      showEditor: true,
      readOnly: false,
      content: {
        json: {
          name: "",
          version: "2022-05-31",
          statements: [
            {
              actions: [],
              resource: "*",
              effect: "Allow"
            }
          ]
        }
      },
      loading: false,
      json_data_initiated: false,
      resources: [],
      policyUpdatedKey: 1,
      disable_update_button: false
    }
  },
  computed: {
    userData() {
      return this.$store.getters[`auth/userData`];
    },
  },
  created() {
    this.getPolicy();
    this.getResources()
  },
  methods: {
    onChange(update) {
      this.content = update
    },

    sanitizeStatements(statements) {
      const fields_to_pick = ["actions", "resource", "effect"]
      return statements.map(statement => pick(statement, fields_to_pick))
    },
    setPolicy(data) {
      this.policy = data
      const { name, statements: raw_statements, version, description } = this.policy;
      const statements = this.sanitizeStatements(raw_statements);
      this.content = { name, statements, version, description }
      this.policyUpdatedKey += 1
    },
    async updatePolicy(payload) {
      try {
        payload.statements = this.sanitizeStatements(payload.statements);
        validatePolicy(payload)

        const { id } = this.$route.params
        const response = await this.useJwt().adminService.updatePolicy(id, payload);
        this.setPolicy(response.data.data);

        await this.updateUserPermissions();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Succcess',
            icon: 'EditIcon',
            variant: 'success',
            text: `Admin policy created successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      }
    },
    async getPolicy() {
      try {
        this.loading = true

        const { id } = this.$route.params
        const response = await this.useJwt().adminService.getPolicy(id);

        this.setPolicy(response.data.data)
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.json_data_initiated = true;
        this.loading = false;
      }
    },
    async getResources() {
      try {
        const response = await this.useJwt().adminService.getResources();
        this.resources = response.data.data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      }
    },
    async updateUserPermissions() {
      const user_id = this.getValueFromSource(this.userData, 'id')

      const { data: { data: user } } = await this.useJwt().adminService.fetchAdmin(user_id);
      store.commit(`auth/${MUTATE_USER_DATA}`, user);
    }
  }
}
</script>
